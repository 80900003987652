import styled, { css } from 'styled-components/macro'
import { Breakpoints } from 'styles/breakpoints'

import { backgroundTextColor, primaryColor, tertiaryColor } from '../../../styles'

export const ButtonStyled = styled.div<{
  appearance: string
  width?: string
  fontSize?: number
  padding?: number
  margin?: boolean
  disabled: boolean
}>`
  ${(props) => {
    let backgroundColor = primaryColor
    let textColor = backgroundTextColor
    let border = ''
    let borderImage = ''
    let backgroundHover = ''

    switch (props.appearance) {
      case 'primary':
        backgroundColor = 'linear-gradient(95.19deg, #4934C3 5.74%, #3AD8ED 100%)'
        textColor = backgroundTextColor
        break
      case 'primary_empty':
        backgroundColor = '#101010'
        textColor = backgroundTextColor
        border = '2px solid'
        borderImage = 'linear-gradient(95.19deg, #4934C3 5.74%, #3AD8ED) 1'

        backgroundHover = 'linear-gradient(95.19deg, #4934C3 5.74%, #3AD8ED 100%)'
        break
      case 'primary_empty_gray':
        backgroundColor = '#232327'
        textColor = backgroundTextColor
        border = '2px solid'
        borderImage = 'linear-gradient(95.19deg, #4934C3 5.74%, #3AD8ED) 1'
        break
      case 'orange_empty':
        backgroundColor = '#101010'
        textColor = backgroundTextColor
        border = '2px solid'
        borderImage = 'linear-gradient(95.79deg, #E72C2C 4.22%, #EFCB4A 102.88%) 1'
        break
      case 'gray_empty':
        backgroundColor = '#101010'
        textColor = backgroundTextColor
        border = '2px solid #61616B;'
        break
      case 'secondary':
        backgroundColor = '#101010'
        textColor = 'white'
        border = '2px solid #9443FF'
        break
      case 'tertiary':
        backgroundColor = tertiaryColor
        textColor = 'black'
        border = '2px solid rgba(0,0,0,0)'
        break
      default:
        backgroundColor = primaryColor
        textColor = backgroundTextColor
        border = '2px solid rgba(0,0,0,0)'
    }

    if (backgroundHover === '') {
      backgroundHover = backgroundColor
    }

    return css`
      border: ${border};
      border-image: ${borderImage};
      background: ${backgroundColor};
      color: ${textColor};
      svg {
        fill: ${textColor};
      }

      &:hover {
        background: ${backgroundHover};
      }
    `
  }}

  transition: all 200ms ease-in-out;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  ${(props) => (props.width ? `width: ${props.width};` : `max-width: 260px;min-width: 220px;`)}
  max-width: 90vw;
  box-sizing: border-box;

  font-size: ${(props) => (props.fontSize ? `${props.fontSize}` : `16`)}px;
  padding: ${(props) => (props.padding ? `${props.padding}` : `0`)}px 0;
  font-weight: 600;
  display: block;
  height: 48px;

  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  margin: ${(props) => (props.margin ? '0 30px' : '')};
  transform: skew(-21deg);
  > svg {
    margin: 11px 0;
    width: 24px;
    height: 24px;
  }

  @media (max-width: ${Breakpoints.md}) {
    font-size: 14px;
  }
`

export const ButtonLoader = styled.img`
  animation: spin 2s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
`

export const ButtonInside = styled.div<{ appearance: string; position?: string }>`
  position: ${(props) => (props.position ? props.position : 'relative')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  > span {
    transform: skew(21deg);
    display: flex;
  }
`
