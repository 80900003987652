import styled from 'styled-components/macro'
import { Breakpoints } from 'styles/breakpoints'

export const MarketplaceCardStyled = styled.div<{
  pointer?: boolean
}>`
  display: flex;
  position: relative;
  justify-content: flex-start;
  flex-direction: column;
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
`
export const CardImage = styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
  cursor: pointer;
`
export const CardDetails = styled.div<{ hovering: boolean; detailsOnHover: boolean }>`
  background: rgba(16, 16, 16, 0.85);
  backdrop-filter: blur(10px);
  width: 100%;
  position: relative;
  padding: 16px;

  @media (max-width: ${Breakpoints.md}) {
    padding: 8px;
  }
`

export const CardName = styled.p`
  font-family: Fira Sans;
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;

  text-transform: uppercase;

  color: #f8fafc;

  text-align: left;
  margin-top: 0px;
  @media (max-width: ${Breakpoints.md}) {
    font-size: 14px;
  }
`

export const CardNumber = styled.span`
  font-family: Fira Sans;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;

  text-transform: uppercase;

  color: #f8fafc;

  text-align: left;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 12px;
  }
`
export const CardEvent = styled.div`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;

  text-transform: uppercase;

  color: rgba(248, 250, 252, 0.7);

  text-align: left;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 10px;
  }
`

export const PriceLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
`

export const CardPriceTitle = styled.div`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  text-transform: uppercase;

  color: #f8fafc;

  text-align: left;

  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`
export const CardPriceMYTV = styled.div`
  font-family: Circe;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: right;

  color: #f8fafc;
  text-align: right;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 12px;
    line-height: 18px;
  }
`

export const CardPriceUSD = styled.div`
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  text-align: right;

  color: rgba(248, 250, 252, 0.7);
  text-align: right;

  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const CardPriceUSDResponsive = styled(CardPriceUSD)`
  font-size: 10px;
  display: none;
  @media (max-width: ${Breakpoints.md}) {
    display: block;
    line-height: 18px;
  }
`

export const CardDetailsButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 9px;

  font-family: Circe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  color: #f8fafc;
`

export const CardGrayed = styled.div`
  position: absolute;
  background-color: rgba(1, 1, 1, 0.7);
  height: 100%;
  width: 100%;
  z-index: 2;
`
