import PrivateRoute from 'app/App.components/PrivateRoute'
import { Activities } from 'pages/Activities/Activities.controller'
import { CardExtended } from 'pages/CardExtended/CardExtended.controller'
import { Challenge } from 'pages/Challenge/Challenge.controller'
import { Collection } from 'pages/Collection/Collection.controller'
import { Landing } from 'pages/Landing/Landing.controller'
import { Marketplace } from 'pages/Marketplace/Marketplace.controller'
import { Pack } from 'pages/Pack/Pack.controller'
import { Profile } from 'pages/Profile/Profile.controller'
import { SalesHistory } from 'pages/SaleHistory/SalesHistory.controller'
import { Tutorials } from 'pages/Tutorials/Tutorials.controller'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Footer } from './App.components/Footer/Footer.controller'
import { Menu } from './App.components/Menu/Menu.controller'
import { Modal } from './App.components/Modal/Modal.controller'
import { ProgressBar } from './App.components/ProgressBar/ProgressBar.controller'
import { ScrollToTop } from './App.components/ScrollToTop/ScrollToTop.view'
import { SupportedTokensHandler } from './App.components/SupportedTokensHandler/SupportedTokensHandler.controller'
import { Toaster } from './App.components/Toaster/Toaster.controller'
import { VersionDebugger } from './App.components/VersionDebugger/VersionDebugger.controller'
import { WalletHandler } from './App.components/WalletHandler/WalletHandler.controller'
import { WrongChain } from './App.components/WrongChain/WrongChain.controller'

export const App = () => {
  return (
    <Router>
      <SupportedTokensHandler />
      <WalletHandler />
      <WrongChain />
      <ScrollToTop />
      <ProgressBar />
      <Menu />
      <VersionDebugger />
      <Switch>
        <Route exact path="/">
          <Landing />
        </Route>
        <Route exact path="/pack">
          <Pack />
        </Route>
        <Route exact path="/marketplace">
          <Marketplace />
        </Route>
        <Route exact path="/assets/:tokenAddress/:tokenId">
          <CardExtended />
        </Route>
        <Route exact path="/profile">
          <PrivateRoute component={<Profile />} />
        </Route>
        <Route exact path="/challenge">
          <Challenge />
        </Route>
        <Route exact path="/tutorials">
          <Tutorials />
        </Route>
        <Route exact path="/sales-history">
          <PrivateRoute component={<SalesHistory />} />
        </Route>
        <Route exact path="/activities">
          <PrivateRoute component={<Activities />} />
        </Route>
        <Route exact path="/collection/:slug">
          <Collection />
        </Route>
        <Route component={Landing} />
      </Switch>
      <Footer />
      <Toaster />
      <Modal />
    </Router>
  )
}
