import styled from 'styled-components/macro'
import { Image } from 'styles'
import { Breakpoints } from 'styles/breakpoints'

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const SliderContentContainer = styled.div`
  @media (max-width: ${Breakpoints.md}) {
    width: 362px;
    overflow: hidden;
  }
  @media (max-width: ${Breakpoints.sm}) {
    width: 80vw;
  }
`

export const SliderContentWrapper = styled.div<{ currentIndex: number }>`
  @media (max-width: ${Breakpoints.md}) {
    display: flex;
    transition: all 250ms cubic-bezier(0.645, 0.045, 0.355, 1);
    ${(props) => `transform: translateX(-${props.currentIndex * 100}%)`};
  }
  @media (min-width: ${Breakpoints.md}) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const SliderArrowWrapper = styled.div<{ show: Boolean }>`
  cursor: pointer;
  width: 32px;
  @media (max-width: ${Breakpoints.sm}) {
    width: 24px;
  }
  > img {
    ${(props) => (!props.show ? 'display: none' : '')}
  }
`

export const SliderCard = styled(Image)<{ selected: Boolean }>`
  @media (min-width: ${Breakpoints.md}) {
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: linear;

    ${(props) => (props.selected ? `width: 23vw; max-width: 430px;` : 'width: 20vw; max-width: 373px;')}

    @media (max-width: ${Breakpoints.lg}) {
      ${(props) => (props.selected ? `width: 30vw; max-width: 430px;` : 'width: 28vw; max-width: 373px;')}
    }
  }
`

export const SliderRadioButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: ${Breakpoints.md}) {
    display: none;
  }
`
export const SliderRadioButton = styled.div<{ selected: Boolean }>`
  width: 24px;
  height: 4px;
  margin: 0 9px;
  cursor: pointer;
  ${(props) =>
    !props.selected ? 'background: #36373E;' : 'background: #9542FF; box-shadow: 0px 0px 44px 7px #9542FF;'}
`

export const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
