import styled from 'styled-components/macro'
import { Breakpoints } from 'styles/breakpoints'

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`

export const SliderContentContainer = styled.div`
  @media (max-width: ${Breakpoints.xxl}) {
    width: 362px;
    overflow: hidden;
  }
  @media (max-width: ${Breakpoints.sm}) {
    width: 100%;
  }
`

export const SliderContentWrapper = styled.div<{ currentIndex: number }>`
  @media (max-width: ${Breakpoints.xxl}) {
    display: flex;
    transition: all 250ms cubic-bezier(0.645, 0.045, 0.355, 1);
    ${(props) => `transform: translateX(-${props.currentIndex * 100}%)`};
  }
  @media (min-width: ${Breakpoints.xxl}) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const SliderRadioButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: ${Breakpoints.xxl}) {
    display: none;
  }
`
export const SliderRadioButton = styled.div<{ selected: Boolean }>`
  width: 24px;
  height: 4px;
  margin: 0 9px;
  cursor: pointer;
  ${(props) =>
    !props.selected ? 'background: #36373E;' : 'background: #9542FF; box-shadow: 0px 0px 44px 7px #9542FF;'}
`

export const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`
