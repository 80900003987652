import { Button } from 'app/App.components/Button/Button.view'
import { CardAction } from 'app/App.components/CardAction/CardAction.controller'
import { ModalType } from 'app/App.components/CardAction/CardAction.enum'
import { MarketplaceCardLoading } from 'app/App.components/Marketplace/MarketplaceCardLoading/MarketplaceCardLoading.view'
import { showModalImg } from 'app/App.components/Modal/Modal.actions'
import { NftEntity, TokenSupportEntity } from 'Entities'
import { ethers } from 'ethers'
import * as React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { State } from 'reducers'
import { Image } from 'styles'
import {
  CardDetails,
  CardDetailsButtonContainer,
  CardEvent,
  CardGrayed,
  CardImage,
  CardName,
  CardNumber,
  CardPriceMYTV,
  CardPriceTitle,
  CardPriceUSDResponsive,
  PriceLine,
  ProfileCardStyled,
} from './ProfileCard.style'

type ProfileCardProps = {
  image: string
  event?: string
  priceUsd: number
  grayed: boolean
  erc721: NftEntity
  loading: boolean
  buyToken: TokenSupportEntity | null
  getErc721?: () => void
  price?: string
  isRevealed: boolean
  openBlister: () => void
}

export const ProfileCardView = ({
  image,
  event,
  priceUsd,
  grayed,
  erc721,
  loading,
  buyToken,
  getErc721,
  price,
  isRevealed,
  openBlister,
}: ProfileCardProps) => {
  const baseImgUrl = '/images/marketplace'
  const history = useHistory()
  const [hovering, setHovering] = useState(false)
  const dispatch = useDispatch()
  const version = useSelector((state: State) => state.version.number)
  const [delayHandler, setDelayHandler] = useState<any>(null)

  const handleMouseEnter = () => {
    setDelayHandler(
      setTimeout(() => {
        setHovering(true)
      }, 200),
    )
  }

  const handleMouseLeave = () => {
    clearTimeout(delayHandler)
    setHovering(false)
  }

  if (loading) {
    return <MarketplaceCardLoading />
  }
  if (image === undefined) {
    return <ProfileCardStyled></ProfileCardStyled>
  }
  return (
    <ProfileCardStyled
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        history.push(`/assets/${erc721.tokenAddress}/${erc721.tokenId}`)
      }}
    >
      {grayed && <CardGrayed />}
      <CardImage alt={`${erc721.name} ${erc721.tokenId}`} src={image} />
      <CardDetails
        hovering={hovering}
        detailsOnHover={hovering && ((version > 0 && !price) || (version === 0 && !isRevealed))}
      >
        <CardName>
          {erc721.name}
          <CardNumber>&nbsp;#{erc721.tokenId}</CardNumber>
        </CardName>
        {event && <CardEvent>{event}</CardEvent>}

        {price && priceUsd && (
          <>
            <Image alt="Card separator" src={`${baseImgUrl}/card_line.svg`} />
            <PriceLine>
              <CardPriceTitle>Price</CardPriceTitle>
              <CardPriceMYTV>
                {ethers.utils.formatUnits(price, buyToken?.decimals)}
                &nbsp;
                {buyToken?.symbol.toUpperCase()}
              </CardPriceMYTV>
              <CardPriceUSDResponsive>~ {priceUsd} USD</CardPriceUSDResponsive>
            </PriceLine>
            {/* <CardPriceUSD>~ {priceUsd} USD</CardPriceUSD> */}
          </>
        )}
        {version > 0 && !price && hovering && (
          <CardDetailsButtonContainer>
            <Button
              appearance="primary"
              width="100%"
              clickCallback={() =>
                dispatch(
                  showModalImg(
                    'Item for sale',
                    <CardAction
                      owner={erc721.ownerAddress!}
                      collection={erc721.name!}
                      type={ModalType.SALE}
                      item={erc721!}
                      callBack={getErc721}
                    />,
                    image,
                  ),
                )
              }
            >
              OFFER FOR SALE
            </Button>
          </CardDetailsButtonContainer>
        )}
        {version === 0 && !isRevealed && hovering && (
          <CardDetailsButtonContainer>
            <Button appearance="primary" width="100%" clickCallback={openBlister}>
              OPEN NOW
            </Button>
          </CardDetailsButtonContainer>
        )}
        {/* <CardDetailsButtonContainer>
          <Button appearance="primary" width="100%" clickCallback={openBlister}>
            OPEN NOW
          </Button>
        </CardDetailsButtonContainer> */}
      </CardDetails>
    </ProfileCardStyled>
  )
}
