// prettier-ignore
import { OpeningPackModal } from 'app/App.components/OpeningPackModal/OpeningPackModal.controller'
import { NftEntity, TokenSupportEntity } from 'Entities'
import { EProtocol } from 'Entities/Marketplace/EthContractConfigEntity'
import { AssetClass } from 'Entities/Marketplace/OrderEntity'
import * as localstorageUtils from 'utils/localstorage'

import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getIpfsLink, isUrl } from 'utils'
import * as Webservices from 'Webservices'
import { State } from '../../reducers/index'
import { CardExtendedView } from './CardExtended.view'

interface IParams {
  tokenAddress: string
  tokenId: string
}

export enum SebastienLoebSeries {
  FAN = 'Sebastien Loeb Fan series',
  PREMIUM = 'Sebastien Loeb Premium',
}

export const CardExtended = () => {
  const version = useSelector((state: State) => state.version.number)
  const params: IParams = useParams()

  const [erc721, setErc721] = useState<NftEntity | null>(null)
  const [image, setImage] = useState('')
  const [sameCollection, setSameCollection] = useState<NftEntity[]>([])
  const supportedTokens = useSelector((state: State) => state.supportedTokens)
  const [buyToken, setBuyToken] = useState<TokenSupportEntity | null>(null)
  const [price, setPrice] = useState<string | null>(null)
  const nativeToken = useSelector((state: State) => state.nativeToken)
  const [isOpenedBlisterModal, setIsOpenedBlisterModal] = useState(false)
  const [isRevealed, setIsRevealed] = useState(false)
  const wallet = useSelector((state: State) => state.wallet)
  const [isRefreshing, setIsRefreshing] = useState(false)

  const setImageFromReveal = React.useCallback(
    (isRevealed: boolean, erc721: NftEntity) => {
      if (!erc721.tokenSupport || !erc721.docUri) return
      if (erc721.ownerAddress !== wallet.address) {
        if (erc721.docUri) setImage(erc721.docUri)
        return
      }
      const frontMetadata = erc721.tokenSupport.metadata?.front
      if (isRevealed || !frontMetadata) {
        if (isUrl(erc721.docUri)) {
          setImage(erc721.docUri)
        } else {
          setImage(getIpfsLink(erc721.docUri))
        }
      } else {
        setImage(frontMetadata!.blister)
      }
    },
    [wallet.address],
  )

  const checkIsRevealed = React.useCallback(
    (erc721: NftEntity) => {
      if (!erc721.tokenSupport) return true
      if (erc721.ownerAddress !== wallet.address) return true
      switch (erc721.tokenSupport.name) {
        case SebastienLoebSeries.PREMIUM:
        case SebastienLoebSeries.FAN:
          if (localstorageUtils.isBlisterAlreadyOpened(erc721.tokenAddress, erc721.tokenId)) return true
          const frontMetadata = erc721.tokenSupport.metadata!.front
          if (new Date().getTime() < new Date(frontMetadata!.revealDate).getTime()) {
            return true
          }
          const maxRevealDate = new Date(frontMetadata!.revealDate)
          maxRevealDate.setDate(maxRevealDate.getDate() + frontMetadata!.revealDelay)
          return new Date().getTime() > maxRevealDate.getTime()
        default:
          return true
      }
    },
    [wallet.address],
  )

  const onVideoEndedBlisterOpening = React.useCallback(() => {
    if (!erc721) return
    setImageFromReveal(true, erc721)
    setIsRevealed(true)
  }, [erc721, setImageFromReveal])

  const getErc721 = React.useCallback(async () => {
    // Getting a single card
    const res = await Webservices.Erc721.Tokens.get(null, {
      q: { tokenAddress: params.tokenAddress, tokenId: ~~params.tokenId },
    })
    if (!res || !res[0]) return
    // If the card exists
    const erc721 = res[0]
    // Setting the card infos
    setErc721(erc721)
    // Update price
    setPrice(erc721.sellOrder?.askTokenAmount ?? null)
    // // Getting the IPFS infos of the card
    // const metadata = await Webservices.Ipfs.get(null, { tokenUri: tokenInfos.tokenUri })
    const isRevealed = checkIsRevealed(erc721)
    setImageFromReveal(isRevealed, erc721)
    setIsRevealed(isRevealed)
  }, [checkIsRevealed, params.tokenAddress, params.tokenId, setImageFromReveal])

  const getSameCollection = React.useCallback(async () => {
    // Getting the NFTs of the same collection
    if (erc721) {
      let sameCollectionData = await Webservices.Erc721.Tokens.get(null, {
        q: { tokenAddress: erc721.tokenAddress },
        limit: 5,
      })
      if (!sameCollectionData) return
      setSameCollection(sameCollectionData)
    }
  }, [erc721])

  const refreshToken = React.useCallback(async () => {
    if (erc721 !== null) {
      setIsRefreshing(true)
      if (
        !(await Webservices.Erc721.Tokens.synchronize(null, {
          tokenAddress: erc721.tokenAddress,
          tokenId: erc721.tokenId,
          force: 1,
        }))
      )
        return
      getErc721()
    }
  }, [getErc721, erc721])

  useEffect(() => {
    if (!erc721?.sellOrder?.askTokenAmount) return
    if (!supportedTokens.length) return
    const order = erc721.sellOrder
    if (order.askAssetClass === AssetClass.ETH) {
      setBuyToken(nativeToken!)
      return
    }
    const supportedToken = supportedTokens.filter(
      (token) => token.protocol === EProtocol.ERC20 && token.address === erc721.sellOrder?.askTokenAddress,
    )
    if (!supportedToken.length) throw Error(`Unsupported token erc20 for order ${erc721.sellOrder._id}`)
    setBuyToken(supportedToken[0])
  }, [erc721, supportedTokens, nativeToken])

  const openBlister = React.useCallback(() => {
    setIsOpenedBlisterModal(true)
  }, [])

  const onCloseBlisterOpening = React.useCallback(() => {
    setIsOpenedBlisterModal(false)
  }, [])

  useEffect(() => {
    getErc721()
  }, [getErc721])

  useEffect(() => {
    getSameCollection()
  }, [getSameCollection])

  return (
    <>
      <CardExtendedView
        version={version}
        erc721={erc721}
        image={image}
        sameCollection={sameCollection}
        buyToken={buyToken}
        refreshToken={refreshToken}
        isRefreshing={isRefreshing}
        price={price}
        getErc721={getErc721}
        openBlister={openBlister}
        isRevealed={isRevealed}
      />
      {erc721 && isOpenedBlisterModal && (
        <OpeningPackModal
          tokenAddress={erc721.tokenAddress}
          tokenId={erc721.tokenId}
          tokenUri={erc721.animationUrl ? erc721.animationUrl : erc721?.docUri ?? ''}
          onClose={onCloseBlisterOpening}
          onVideoEnded={onVideoEndedBlisterOpening}
          openingVideo={erc721.tokenSupport?.metadata?.front?.openingVideo ?? ''}
        />
      )}
    </>
  )
}
